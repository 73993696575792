import { NotyfService } from "./services/notyf/notyf.service";
import { EnvironmentService } from "./services/environment/environment.service";
import { ApiService } from "./services/api/api.service";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { FcmService } from "./services/fcm/fcm.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { CartService } from "./services/cart/cart.service";
import { SettingsService } from "./services/settings/settings.service";
import { Component, OnChanges } from "@angular/core";

import { AlertController, Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Market } from "@ionic-native/market/ngx";
import { Category } from "./interfaces/category";
import { CategoriesService } from "./services/categories/categories.service";

import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";

const CART_EXTERNAL_REFRESH_TIMEOUT = 60 * 60 * 1000; // 1 hour;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  loading = true;
  loadingModal: HTMLIonLoadingElement;
  currentPlatform: string = "";
  cart_timeout;

  categories: Category[] = [];

  app_version: string = "1.0.5";

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private settings: SettingsService,
    private auth: AuthService,
    private fcm: FcmService,
    private categoriesService: CategoriesService,
    private cartService: CartService,
    private screenOrientation: ScreenOrientation,
    private appVersion: AppVersion,
    private api: ApiService,
    private alertController: AlertController,
    private market: Market,
    private env: EnvironmentService,
    private notyf: NotyfService
  ) {
    // set angular to always run ngOnInit when navigating to a page which has already been loaded in current session (e.g view-appointments)
    // this fixes the issue where, for example, new appointments dont show in view appointments when that page has been visited before a new booking was made
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.initializeApp();
  }

  async initializeApp() {
    // this.geolocation.getCurrentCoordinates().then((coords: Coordinates) => {
    //   this.geolocation.getAddress().then((address: string) => {
    //     console.log({address});
    //   }).catch((error: any) => {
    //     console.log('There was an error getting the address from the coordinates');
    //   })
    // }).catch((error: any) => {
    //   console.log('There was an error fetching the coordinates');
    // });

    this.api.getApiUrl();

    this.platform.ready().then(async () => {

      this.app_version = await this.appVersion.getVersionNumber();

      this.statusBar.styleDefault();
      await this.loadPlatform();

      if (this.currentPlatform !== "web") {
        // lock the screen orientation for mobile apps - saves some css styling xD
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        // Get the version from config.xml values.
        // We cant use the ENV values like previous projects since they are now fetched dynamically instead of being hard coded during build.

        // check the version of this app to the latest version available on the app store
        await this.checkVersion();
      }

      this.registerCartRefresh();
      this.getCategories();
      this.splashScreen.hide();
    });
  }

  registerCartRefresh() {
    this.auth.loggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn === true) {
        this.cartService.getCartExternal().catch((err) => {
          console.log("error getting external cart");
          console.log({ err });
          this.auth.logout();
          this.notyf.error("You have been logged out.");
        });

        this.cart_timeout = setInterval(() => {
          this.cartService.getCartExternal().catch((err) => {
            console.log("error getting external cart");
            console.log({ err });
          });
        }, CART_EXTERNAL_REFRESH_TIMEOUT);
      } else {
        clearInterval(this.cart_timeout);
      }
    });
  }

  async checkVersion() {

    const response = await this.api
      .get("version-check", {
        version: this.app_version,
        salon_id: this.env.api.salon_id,
        platform: this.currentPlatform,
      })
      .toPromise();

    console.log({ response });
    
    if (response["valid"] === false) {
      const alert = await this.alertController.create({
        header: "Update Available",
        backdropDismiss: false,
        message: "A newer version of this app is available. Please update to continue.",
        buttons: [
          {
            text: "Update",
            handler: () => {
              this.openAppStore(response["link"]);
              // console.log({ link: response["link"] });
              this.notyf.error({
                message: `Link: ${response["link"]}`,
                duration: 5000,
                dismissible: true,
              });

              // returning false keeps the alert open when the user clicks the button
              return false;
            },
          },
        ],
      });

      await alert.present();
    }
  }

  async loadPlatform() {
    this.currentPlatform = await this.settings.getPlatform();
  }

  async getCategories() {
    this.categories = await this.categoriesService.getCategories();
  }

  async openAppStore(appId: string) {
    const response = await this.market.open(appId);
  }
}
