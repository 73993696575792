import { SettingsService } from 'src/app/services/settings/settings.service';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { Category } from 'src/app/interfaces/category';
import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  runtimePlatform: string;
  currentPlatform : string;

  categories: Category[] = [];

  has_about_us_page: boolean = false;
  has_gallery_page: boolean = false;

  facebook_url: string = null;
  instagram_url: string = null;
  twitter_url: string = null;
  youtube_url: string = null;

  constructor(
    private platform: Platform,
    private categoriesService: CategoriesService,
    private settingsService: SettingsService,
  ) { }

  async ngOnInit() {
    // this.platform.pause
    this.runtimePlatform = this.platform.is('hybrid') || this.platform.is('mobile') ? 'mobile' : 'web';

    this.currentPlatform = await this.settingsService.getPlatform();

    console.log(this.runtimePlatform,"                  currentPlatform    "+ this.currentPlatform);

    this.categoriesService.categories$.subscribe(categories => {
      this.categories = categories;
    })

    this.settingsService.settings$.subscribe(response => {
      this.has_about_us_page = (response.app.has_about_us_page === 'yes');
      this.has_gallery_page = (response.app.has_gallery_page === 'yes');

      if (response.social_media.salon_facebook_url) {
        this.facebook_url = response.social_media.salon_facebook_url
      }

      if (response.social_media.salon_instagram_url) {
        this.instagram_url = response.social_media.salon_instagram_url
      }

      if (response.social_media.salon_twitter_url) {
        this.twitter_url = response.social_media.salon_twitter_url
      }

      if (response.social_media.salon_youtube_url) {
        this.youtube_url = response.social_media.salon_youtube_url
      }
    });
  }

  setActiveCategory(category: Category) {
    this.categoriesService.setActiveCategory(category);
  }
}
